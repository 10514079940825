import * as React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/free-solid-svg-icons"
import ReactEmbedGist from "react-embed-gist"
import Layout from "../components/layout"
import Seo from "../components/seo"

const isBrowser = typeof window !== "undefined"

const Project = ({ project }) => {
  const { title, description, image, links, technologies } = project.node

  return (
    <div className="rounded overflow-hidden shadow-lg bg-white mb-3 flex flex-col">
      <div>
        {image && (
          <div className="image-container">
            <img className="w-full" src={image.url} alt={image.alt} loading="lazy" />
          </div>
        )}
      </div>

      <div>
        <div className="px-6 py-4 pb-0">
          <div className="font-bold text-xl mb-2">{title}</div>
          <p className="text-slate-700 text-base">{description}</p>
        </div>
        {links && (
          <div className="px-6 py-4 pb-0 text-xs">
            <p>
              {links.map(link => (
                <a key={link.title} className="mr-3" href={link.link} target="_blank" rel="noreferrer">
                  {link.title} <FontAwesomeIcon icon={faLink} />
                </a>
              ))}
            </p>
          </div>
        )}
        <div className="px-6 py-4">
          {technologies &&
            technologies.map(technology => (
              <span key={technology} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                {technology}
              </span>
            ))}
        </div>
      </div>
    </div>
  )
}

const Projects = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const projects  = data.allProjectsJson.edges
  const isOdd     = projects.length % 2 !== 0

  if (!isBrowser) {
    return null;
  }

  return (
    <Layout location={location} title={siteTitle}>
      <div>
        <h2 className="font-semibold text-2xl">
          <a className="text-slate-500 italic mb-10" href="#policy">
            #.
          </a>{" "}
          Policy
        </h2>

        <div className="prose lg:prose-lg prose-slate prose-code:before:content-none prose-code:after:content-none">
          <h3>Privacy Policy for apps developed by Demóstenes García (PIXMAT)</h3>

          <p>
            This includes:
          </p>

          <ul>
            <li>Cacerolazo Panamá</li>
          </ul>

          <p>This Privacy Policy explains how personal information is collected, used, and disclosed by Demóstenes García G. and/or PIXMAT ("we" or "us") in relation to your use of our mobile applications available on Google Play and the App Store (the "Apps"). We are committed to protecting your privacy and will only use your personal information in accordance with this Privacy Policy.</p>

          <h3>Information We Collect</h3>

          <p><strong>Information You Provide:</strong> We may collect and store information you provide when using our Apps, including but not limited to your name, email address, and other contact information.</p>

          <p><strong>Automatically Collected Information:</strong> When you use our Apps, certain information may be automatically collected, including your device information, IP address, mobile operating system, and usage information about your interaction with the Apps.</p>

          <h3>How We Use Your Information</h3>

          <p>We may use the information we collect for various purposes, including:</p>

          <ul>
            <li>Providing and improving our Apps</li>
            <li>Personalizing your experience</li>
            <li>Responding to your inquiries and providing customer support</li>
            <li>Sending you technical notices, updates, security alerts, and support and administrative messages</li>
            <li>Analyzing usage trends and improving the functionality of the Apps</li>
            <li>Sharing Your Information</li>
          </ul>

          <p><strong>We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third-party service providers who assist us in operating our Apps, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.</strong></p>

          <h3>Security</h3>

          <p>We take reasonable measures to help protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>

          <h3>Your Choices</h3>

          <p>You can always opt not to disclose certain information, even though it may be needed to take advantage of some of our App features.</p>

          <h3>Changes to This Privacy Policy</h3>

          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.</p>

          <h3>Contact Us</h3>

          <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:developer@demogar.com">developer@demogar.com</a>.</p>
        </div>
      </div>
    </Layout>
  )
}

export default Projects

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Projects" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    },
    allProjectsJson {
      edges {
        node {
          title
          description
          image {
            url
            alt
          }
          links {
            title
            link
          }
          technologies
        }
      }
    }
  }
`
